// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData } from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import withExtraction from '../lib/withExtraction'

// components
import SEO from '../components/SEO'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  HeaderBar,
  Testimonials,
} from '../components'
import { Page, PageContent } from '../components/Page'
import CoursesBox from '../components/CoursesBox'
import CourseDetailBox from '../components/CourseDetailBox'
import { getCategoriesKeys } from '../courses'
import headerBarImageSrc from '../data/images/courses-top.png'

const groupCourseTypesByCategory = (allCourseTypes = []) => {
  const categories = {}
  allCourseTypes.forEach(type => {
    if (!categories[type.category]) {
      categories[type.category] = []
    }
    categories[type.category].push(type)
  })
  return categories
}

const categoriesKeys = getCategoriesKeys()

const TopImage = styled.img`
  padding: 53px 0 56px 0;
`

class Courses extends React.Component {
  state = {
    activeCategoryKey: categoriesKeys[0],
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const cat = urlParams.get('active')
    if (categoriesKeys.includes(cat)) {
      this.setActiveCategory(cat)
    }
  }

  setActiveCategory = activeCategoryKey => {
    this.setState({ activeCategoryKey })
  }

  render() {
    const { activeCategoryKey } = this.state
    const {
      location: { pathname },
      intl: { language },
      data: { allCourseTypes },
    } = this.props

    const coursesTypesByCategories = groupCourseTypesByCategory(allCourseTypes)

    const activeCategory = coursesTypesByCategories[activeCategoryKey]

    return (
      <>
        <SEO
          title="Kurzy a akce"
          pathname={pathname}
        />
        <HeaderBar>
          <HeaderBar.Content>
            <HeaderBar.Title>Kurzy a akce</HeaderBar.Title>
            <HeaderBar.SubTitle>
              Nabídka kurzů, seminářů a konferencí
            </HeaderBar.SubTitle>
          </HeaderBar.Content>
          {/* <HeaderBar.ImageWrapper mobileMaxWidth="450px">
            <img src={headerBarImageSrc} alt="" />
          </HeaderBar.ImageWrapper> */}
        </HeaderBar>
        <Page grey>
          <PageContent>
            <Gap gap="90px" />

            <CoursesBox
              coursesTypesByCategories={coursesTypesByCategories}
              setActiveCategory={this.setActiveCategory}
              activeCategoryKey={activeCategoryKey}
            />

            {activeCategoryKey &&
              activeCategory &&
              activeCategory.map((courseType, index) => (
                <>
                  <Gap gap="60px" />
                  <Gap gap="0" id={Link.anchors.COURSE_DETAILS} />
                  <CourseDetailBox
                    courseType={courseType}
                    key={courseType.id}
                    setActiveCategory={this.setActiveCategory}
                  />
                </>
              ))}

            <Gap gap="176px" mobileGap="44px" />
          </PageContent>
        </Page>
        <Gap gap="150px" mobileGap="33px" />
        <Page>
          <PageContent>
            <Testimonials />
          </PageContent>
        </Page>
        <Gap gap="194px" mobileGap="63px" />
      </>
    )
  }
}

// {Object.keys(coursesTypesByCategories).map(categoryKey => (
//   <div>
//     categoryKey: {categoryKey} <br />
//     {/* popisky kategorii dat nekam do souboru */}
//     {coursesTypesByCategories[categoryKey].map(type => (
//       <div>
//         type : {type.name} {type.longName}
//         {type.courseTerms.map(t => t.fromToDesc)}
//       </div>
//     ))}
//   </div>
// ))}

export default injectIntl(withExtraction(Courses))

export const pageQuery = graphql`
  query {
    ks {
      allCourseTypes {
        ...CourseTypeFragment
        ...CourseTypeNestedFragment
      }
    }
  }
`
