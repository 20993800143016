/* eslint-disable react/no-danger */
import Select from 'react-select'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Text from './Text'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Link from './Link'
import Title from './Title'
import Button from './Button'
import ListingCourses from './ListingCourses'
import { Line, RH } from '.'
import { Page, PageContent } from './Page'
import T from '../lib/intl/T'
import { up, addPagePadding } from '../lib/styles'
import pagesConfig from '../../pagesConfig'
import injectIntl from '../lib/intl/injectIntl'
import { categories, getCategoriesKeys } from '../courses'
import { PureCheckbox } from './fields/Checkbox'

const Tab = styled.div`
  ${({ theme: { colors }, isActive }) => css`
    box-shadow: 6px 10px 81px rgba(0, 0, 0, 0.1);
    background-color: #f3f5f9;
    height: 86px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    cursor: pointer;
    background-color: #f9ad3d;

    ${isActive &&
      css`
        background-color: white;
        box-shadow: none;
      `}
  `}
`

const ListingWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: white;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    align-items: flex-start;
  `}
`

const SelectWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    box-shadow: -2px 11px 81px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 0 16px;
  `}
`

const categoriesKeys = getCategoriesKeys().filter(c => c !== 'LPAD')

// tabs for select
const tabOptions = categoriesKeys.map(key => ({
  value: key,
  label: categories[key].label,
}))

// or todays
const getTodaysOrPastTerms = (terms, count = 10000) => {
  const now = moment()
  let lastHappeningTerms = terms.filter(term => {
    const from = moment(term.from, 'YYYY-MM-DD')
    return from.isSameOrBefore(now)
  })

  // sort it
  lastHappeningTerms = lastHappeningTerms
    .sort((a, b) =>
      moment(a.from, 'YYYY-MM-DD').isBefore(moment(b.from, 'YYYY-MM-DD'))
        ? 1
        : -1)
    .splice(0, count)
    .sort((a, b) =>
      moment(a.from, 'YYYY-MM-DD').isBefore(moment(b.from, 'YYYY-MM-DD'))
        ? -1
        : 1)

  // console.log('get past terms', lastHappeningTerms, terms, count)

  return lastHappeningTerms
}

const getPastTerms = (terms, count = 10000) => {
  const now = moment()
  let lastHappeningTerms = terms.filter(term => {
    const from = moment(term.from, 'YYYY-MM-DD')
    return from.isBefore(now)
  })

  // sort it
  lastHappeningTerms = lastHappeningTerms
    .sort((a, b) =>
      moment(a.from, 'YYYY-MM-DD').isBefore(moment(b.from, 'YYYY-MM-DD'))
        ? 1
        : -1)
    .splice(0, count)
    .sort((a, b) =>
      moment(a.from, 'YYYY-MM-DD').isBefore(moment(b.from, 'YYYY-MM-DD'))
        ? -1
        : 1)

  // console.log('get past terms', lastHappeningTerms, terms, count)

  return lastHappeningTerms
}

const getFutureTerms = (terms, count = 10000) => {
  const now = moment()
  return terms
    .filter(term => {
      const from = moment(term.from, 'YYYY-MM-DD')
      return from.isAfter(now)
    })
    .splice(0, count)
}

const getTodaysOrFutureTerms = (terms, count = 10000) => {
  const now = moment()
  return terms
    .filter(term => {
      const from = moment(term.from, 'YYYY-MM-DD')
      const to = moment(term.to, 'YYYY-MM-DD')
      return to.isSameOrAfter(now)
    })
    .splice(0, count)
}

const saveUsedTerms = (terms, cache) => {
  terms.forEach(term => {
    cache[term.id] = true
  })
}

const filterUsedTermsOut = (terms, cache) =>
  terms.filter(term => !cache[term.id])

const filterDuplicities = terms => {
  const cache = {}

  const results = []
  terms.forEach(term => {
    if (!cache[term.id]) {
      results.push(term)
      cache[term.id] = true
    }
  })

  return results
}

const getActiveFilterKey = (activeFilters = {}) => {
  let activeKey = null
  Object.keys(activeFilters).forEach(key => {
    if (activeFilters[key]) {
      activeKey = key
    }
  })
  return activeKey
}

class CoursesBox extends React.Component {
  state = {
    showAll: false,
    filters: {
      FIE_STANDARD: {},
    },
    filterHistoryOnly: false,
  }

  setActiveTab = activeCategoryKey => {
    this.props.setActiveCategory(activeCategoryKey)
  }

  loadMore = () => {
    this.setState({
      showAll: true,
    })
  }

  render() {
    // courses grouped by tab key
    const {
      coursesTypesByCategories,
      activeCategoryKey,
      intl: { t },
    } = this.props
    const { showAll, filters, filterHistoryOnly } = this.state

    // this tab config
    const activeCategory = categories[activeCategoryKey]

    // console.log(
    //   'activeCategoryKey',
    //   activeCategoryKey,
    //   categoriesKeys,
    //   categories,
    // )
    // console.log('coursesTypesByCategories', coursesTypesByCategories)

    // this tab courses by types
    let activeCoursesTypes = coursesTypesByCategories[activeCategoryKey] || []

    const now = moment()
    const yearAgo = moment().subtract(1, 'year')

    // console.log('yearAgo', yearAgo.toString())

    // console.log('activeCoursesTypes', activeCoursesTypes)
    // sort terms, and filter out more then 1 yr old
    activeCoursesTypes = activeCoursesTypes.map(type => ({
      ...type,
      courseTerms: type.courseTerms
        .filter(term => moment(term.from).isAfter(yearAgo))
        .filter(term => term.isPublished)
        .sort((a, b) => moment(a.from).isBefore(b.from) ? -1 : 1),
    }))

    const activeFilters = filters[activeCategoryKey]
    let itemsToList = []

    const showLoadMoreButton = false

    // UPDATE 12.10.2020:
    // - u FIE Standard - dva ukončené kurzy a všechny kurzy probíhající a budoucí,
    // - u FIE Basic - dva ukončené kurzy a všechny kurzy probíhající a budoucí,
    // - u LPAD jen probíhající nebo budoucí kurz,
    // - u seminářů jen probíhající nebo budoucí,
    // - u konferencí jen probíhající nebo budoucí.

    // pokud mame filtry == FIE STANDARD
    if (activeCategory.filters) {
      const usedTerms = {}

      // pokud jsou vsechny filtry vyple
      if (!getActiveFilterKey(activeFilters) && !filterHistoryOnly) {
        // dva ukončené kurzy a všechny kurzy probíhající a budoucí

        let typeTerms = []

        activeCoursesTypes.forEach(type => {
          typeTerms = [...typeTerms, ...type.courseTerms || []]
        })

        // console.log('typeTerms', typeTerms)

        const pastTerms = getPastTerms(typeTerms, 2)
        const todaysOrFutureTerms = getTodaysOrFutureTerms(typeTerms)
        itemsToList = [...itemsToList, ...pastTerms, ...todaysOrFutureTerms]
        saveUsedTerms([...pastTerms, ...todaysOrFutureTerms], usedTerms)

        // console.log('getPastTerms(typeTerms, 2)', getPastTerms(typeTerms, 2))
        // console.log('getTodaysOrFutureTerms(typeTerms)', getTodaysOrFutureTerms(typeTerms))
        // a filter is active
      } else if (filterHistoryOnly) {
        // historic filter
        activeCoursesTypes.forEach(type => {
          const pastTerms = getTodaysOrPastTerms(type.courseTerms)
          itemsToList = [...itemsToList, ...pastTerms]
        })
      } else {
        // type filter is on
        // console.log('type filter is on')
        const activeFilterKey = getActiveFilterKey(activeFilters)
        const type = activeCoursesTypes.find(
          type => type.name === activeFilterKey,
        )
        // console.log('activeFilterKey', type)
        const pastTerms = getPastTerms(type.courseTerms, 2)
        const futureTerms = getTodaysOrFutureTerms(type.courseTerms)
        itemsToList = [...itemsToList, ...pastTerms, ...futureTerms]
      }
    } else {
      // console.log('OTHER then FIE STANDARD')
      // other than FIE STANDARD

      switch (activeCategoryKey) {
        case 'FIE_BASIC': {
          activeCoursesTypes.forEach(type => {
            const pastTerms = getPastTerms(type.courseTerms, 2)
            const futureTerms = getTodaysOrFutureTerms(type.courseTerms)

            itemsToList = [...itemsToList, ...pastTerms, ...futureTerms]
          })
          break
        }
        // everything other
        default: {
          activeCoursesTypes.forEach(type => {
            const futureTerms = getTodaysOrFutureTerms(type.courseTerms)

            itemsToList = [...itemsToList, ...futureTerms]
          })
          break
        }
      }
    }

    // finally sort everything

    itemsToList = itemsToList.sort((a, b) =>
      moment(a.from).isBefore(b.from) ? -1 : 1)

    // console.log('before filter itemsToList', itemsToList)

    itemsToList = filterDuplicities(itemsToList)

    /**
     * sorting:
     * fie-standard: (bez filtrace)
     * - razeni dle urovne kurzu
     * - FIE 1:
     *    - vidim max 2 probihajici/probehle zaroven max 3 mesice dozadu,
     *    - 2 prvni planovane
     * - FIE 2 a 3:
     *    - vidim max 1 planovany
     * - po kliku na dalsi terminy, nactem veskery zbytek, jiz ale chronologicky bez ohledu na uroven!
     *
     *  fie-standard: (s filtraci)
     *  - pri zaskrtnuti urovne vidim vse v dane urovni kurzu avsak porad max 3 mesice dozadu nebo max 2 probehle/probihajici
     *  - zaskrtnuti probehle kurzy -> vidim pouze probehle kurzy za posledni rok?
     *  - navrhuji umoznit zaskrtnout pouze jeden checkbox
     *
     *  ostatni zalozky:
     *  - razeni chronologicky bez ohledu na uroven/typ
     *  - historie dozadu uplne stejne - 2 kusy zaroven max 3 mesice dozadu
     *  - bez filtrace na probehle polozky (nebo s filtraci?)
     *
     *
     *
     * UPDATE:
     *
fie-standard (bez filtrace - vychozi pohled):
 - primarni razeni dle urovne kurzu
 - FIE 1:
  - vidim max 2 probihajici/probehle,
  - 3 prvni planovane
 - FIE 2 a 3:
   - vidim max 1 planovany, zadne historicke
 - po kliku na dalsi terminy, nactem veskery zbytek planovanych, stale primarne dle urovne

 fie-standard: (s filtraci)
 - pri zaskrtnuti filtru urovne vidim vse v dane urovni kurzu avsak
porad max 2 probehle/probihajici (FIE II a III 1 probihajici a 1 probehly)
 - zaskrtnuti probehle kurzy -> vidim uplne vsechny probehle kurzy za
posledni rok
 - navrhuji umoznit zaskrtnout pouze jeden checkbox, ne vic zaroven - ANO

 ostatni zalozky:
 - razeni chronologicky bez ohledu na uroven/typ
 - historie dozadu uplne stejne - max 2 kusy, LPAD a konf. jen jeden kus
 - bez filtrace

     */

    return (
      <>
        <Col id={Link.anchors.COURSES_BOX}>
          <RH desktop>
            <Row>
              {categoriesKeys.map((catKey, index) => {
                const cat = categories[catKey]
                const isActive = catKey === activeCategoryKey
                return (
                  <>
                    {index !== 0 && <Gap.Fluid min="6px" max="11px" />}
                    <Tab
                      key={catKey}
                      isActive={isActive}
                      onClick={() => {
                        this.setActiveTab(catKey)
                      }}
                    >
                      <Title.MiniOrange
                        letterSpacing="2.2px"
                        fontWeight="700"
                        color={isActive ? 'orange' : 'white'}
                      >
                        {cat.label}
                      </Title.MiniOrange>
                    </Tab>
                  </>
                )
              })}
            </Row>
          </RH>

          <RH mobile>
            <SelectWrapper>
              <Col alignSelf="center" alignItems="center" width="100%">
                <Gap gap="24px" />
                <Title.Section>Vyberte si typ kurzu / druh akce</Title.Section>
                <Gap gap="26px" />
                <Col width="100%" maxWidth="320px">
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                    })}
                    options={tabOptions}
                    value={tabOptions.find(
                      opt => opt.value === activeCategoryKey,
                    )}
                    isSearchable={false}
                    isClearable={false}
                    onChange={({ value } = {}, { action } = {}) => {
                      if (action === 'select-option' && value) {
                        this.setActiveTab(value)
                        // console.log('value', value)
                      }
                    }}
                  />
                </Col>
                <Gap gap="40px" />
              </Col>
            </SelectWrapper>
            <Gap gap="32px" />
          </RH>

          <ListingWrapper>
            <Gap gap="66px" />
            <Row responsive>
              <Title.Section color="#284c36">
                {activeCategory.boxTitle || activeCategory.label}&nbsp;&nbsp;
              </Title.Section>
              {activeCategory.subtitle && (
                <Title.Section
                  color="#284c36"
                  fontSize="42px"
                  mobileFontSize="18px"
                >
                  {activeCategory.subtitle}
                </Title.Section>
              )}
            </Row>

            <Gap gap="80px" />

            <Row
              justifyContent="space-between"
              alignSelf="center"
              width="100%"
              responsive
            >
              <Text maxWidth="800px">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(activeCategory.description),
                  }}
                />
              </Text>

              <Gap.Fluid min="16px" grow="20" mobileMin="34px" />

              <Col minWidth="215px">
                {!activeCategory.hideAboutButton && (
                  <Button.SecondaryOrange
                    iconLeft="doubleArrowDown"
                    paddingRight="14px"
                    paddingLeft="18px"
                    iconGap="8px"
                    alignSelf="center"
                    link={{
                      anchor: Link.anchors.COURSE_DETAILS,
                    }}
                  >
                    více informací
                  </Button.SecondaryOrange>
                )}
              </Col>
            </Row>

            {activeCategoryKey === 'FIE_STANDARD' && (
              <>
                <Gap gap="40px" />
                <Text fontSize="15px" lineHeight="28px" fontWeight="600">
                  Typ kurzu:
                </Text>
                <Gap gap="20px" />
                <Row responsive wrap="wrap">
                  {activeCoursesTypes.map(type => (
                    <React.Fragment key={type.id}>
                      <PureCheckbox
                        label={(
                          <Row alignItems="center">
                            <Text
                              fontSize="15px"
                              lineHeight="28px"
                              fontWeight="600"
                              whiteSpace="nowrap"
                            >
                              {type.name}
                            </Text>
                            <Text
                              fontSize="15px"
                              lineHeight="28px"
                              whiteSpace="nowrap"
                            >
                              &nbsp; ({type.miniDesc})
                            </Text>
                          </Row>
                        )}
                        name={type.name}
                        value={activeFilters && activeFilters[type.name]}
                        onChange={value => {
                          this.setState(state => ({
                            filters: {
                              ...state.filters,
                              [activeCategoryKey]: {
                                // ...state.filters[activeCategoryKey],
                                [type.name]: value,
                              },
                            },
                            filterHistoryOnly: false,
                          }))
                        }}
                      />
                      <Gap gap="40px" mobileGap="24px" />
                    </React.Fragment>
                  ))}

                  <PureCheckbox
                    label={(
                      <Row alignItems="center">
                        <Text
                          fontSize="15px"
                          lineHeight="28px"
                          fontWeight="600"
                          whiteSpace="nowrap"
                        >
                          Proběhlé kurzy
                        </Text>
                      </Row>
                    )}
                    name="filterHistoryOnly"
                    value={filterHistoryOnly}
                    onChange={value => {
                      this.setState({
                        filterHistoryOnly: value,
                        filters: {},
                      })
                    }}
                  />
                </Row>
              </>
            )}

            <Gap gap="68px" />

            <Col width="100%" alignItems="stretch">
              <ListingCourses
                items={itemsToList}
                categoryConfig={activeCategory}
              />
            </Col>

            <Gap gap="60px" />

            {showLoadMoreButton && (
              <>
                <Col alignSelf="center">
                  <Button.PrimaryOrange
                    iconLeft="arrowDown"
                    onClick={this.loadMore}
                  >
                    Další termíny
                  </Button.PrimaryOrange>
                </Col>

                <Gap gap="68px" />
              </>
            )}
          </ListingWrapper>
        </Col>
      </>
    )
  }
}

CoursesBox.defaultProps = {
  onCategoryChange: () => {},
}

export default injectIntl(CoursesBox)
